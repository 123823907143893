import React from "react";
import ExamplesSection from "../../containers/ExamplesSection";
import MainLayout from "../../components/MainLayout";

export default () => {
  return (
    <MainLayout
      hasGradient
      title="Use Cases"
      pathname="/tools/use-cases"
      description="Here’s some inspiration to help get you started with your own tests."
      tool={{ url: "/use-cases", title: "Use Cases for VisualEyes" }}
    >
      <ExamplesSection />
    </MainLayout>
  );
};
